
import Puppet from "./puppet"
import * as Phaser from 'phaser'
import Preload from './preload'

export default function(boot){
    console.log("BOOT DATA",boot)
    var preload=null
    var reactive=null
    var data={}
    var scenes=[]
    var client=null
    var puppet=Puppet(this)
    var rooms={}
    var loadPromises=[]

    function loadProcess(p){
        loadPromises.push(new Promise(function(resolve,reject){
            p(resolve,reject)
        }).then(function(d={}){
            //TODO: check the data
            Object.assign(data,d)
        }))
    }

    return {
        preload:function(assets){
            preload=assets
        },
        ui:function(uiData){
            if(reactive!=null){
                throw new Error("UI already set")
            }
            reactive=uiData
        },
        get:function(key){
            return data[key]
        },
        set:function(key,value){
            if(key in data){
                throw new Error("Data "+key+" already set")
            }
            data[key]=data
        },
        loadProcess,
        game:function(config,afterPreload="main"){
            //TODO: preload Preload(assets,)
            var r=null
            new Promise(function(resolve,reject){
                r=resolve
            })           
            var s=[
                Preload(assets,r,afterPreload),
                ...scenes
            ]
            assets=[]
            return new Phaser.Game(Object.assign({},config,{scene:s}))
        },
        scene:function(scene,config={}){
            scenes.push(scene(this))
        },
        leave(room){
            rooms[room].leave()
            delete rooms[room]
        },
        room(room){
            if(!room in rooms){
                throw new Error("Room "+room+" is not defined")
            }
            return rooms[room]
        },
        connect(url){
            if(client!=null){
                throw new Error("server already created")
            }
            client=Server(rooms,url)
        }
    }
}