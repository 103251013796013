import axios from 'axios' 

const axiosConf={
  baseURL: 'https://alphaapi.flickyfloobs.com'
}
if(process.env.DEV){
  axiosConf.baseURL='http://localhost:3755'
}

const axiosInstance = axios.create(axiosConf)
export default ({ Vue }) => {
  Vue.prototype.$axios = axiosInstance
}
export { axiosInstance }