export default function(assets,cb,nextKey="main",logic=function(){}){
    return new class extends Phaser.Scene {
        constructor () {
            super({key:"preload"});
        }
        preload () {
            for(a in assets){
                this.load[a.type](a.key,a.path)
            }
            logic()
        }
        create ()  {
            console.log("PRELOAD DID COMPLETE")
            var that=this
            cb()
        }
    }
}
